<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_disbursement" title="NUEVO DESEMBOLSO">
      <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
        <b-row>
          <b-col md="2">
            <b-form-group label="Nro Crédito:">
              <b-form-input disabled v-model="disbursement.code_credit"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Tipo de Programa:">
              <b-form-select disabled v-model="disbursement.id_product_type" :options="products_type"></b-form-select>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Medio de pago:">
              <b-form-select v-model="disbursement.payment_method" :options="payment_method"></b-form-select>
              <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Nro de Referencia:">
              <b-form-input type="text" v-model="disbursement.code"></b-form-input>
              <small v-if="errors.code"  class="form-text text-danger" >Ingrese un nro refencia</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Fecha:">
              <b-form-input type="date" class="text-center" v-model="disbursement.date"></b-form-input>
              <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group label="Monto:">
              <b-form-input disabled type="number" step="any" class="text-right" v-model="disbursement.amount"></b-form-input>
              <small v-if="errors.amount"  class="form-text text-danger" >Ingrese un monto</small>
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Anexos:">
                <b-form-file accept="application/pdf"  v-model="file" :state="Boolean(file)" @change="onFileChange" placeholder="Selecciona un archivo" drop-placeholder="Arrastre el archivo aqui"></b-form-file>
              <small v-if="errors.file"  class="form-text text-danger" >Seleccione un documento</small>
            </b-form-group>
          </b-col>

          <b-col md="8">
            <b-form-group label="Observación:">
              <b-form-textarea v-model="disbursement.description"></b-form-textarea>
            </b-form-group>
          </b-col>

          <b-col md="5"></b-col>
          <b-col md="2">
            <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
          </b-col>
          
        </b-row>
      </b-form>
    </b-modal>
    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "DisbursementAdd",
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      modal_disbursement:false,
      module: 'Disbursement',
      role: 2,
      disbursement: {
          id_disbursement:'',
          id_credit:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_module:'',
          code:'',
          code_credit:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          description:'',
          file:'',
          amount:'',
          state:1,
          created_at:'',
          updated_at:'',
      },
      file:null,
      modules:[],
      products_type:[],
      payment_method:[
        {value:'008', text:'EFECTIVO'},
        {value:'007', text:'CHEQUE'},
        {value:'003', text:'TRANSFERENCIA'},
      ],
      client: null,
      clients: [],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],

      errors: {
        payment_method: false,
        date: false,
        amount: false,
      },
      validate: false,
    };
  },
  mounted() {
    EventBus.$on('ModalDisbursementShow', (data) => {
      this.modal_disbursement = true;
      this.disbursement.id_credit = data.id_credit;
      this.disbursement.id_client = data.id_client;
      this.disbursement.amount = data.amount;
      this.disbursement.id_product_type = data.id_product_type;
      this.disbursement.code_credit = data.code_credit;
      
    });
    this.ListModule();
    this.ListProductsType();
  },
  methods: {
    AddDisbursement,
    Validate,
    ListProductsType,
    SearchClients,
    ShowModalClient,
    onFileChange,
    ListModule,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function ListProductsType() {
  let me = this;
  let url = me.url_base + "product/list-product-type";
  me.products_type = [{ value: '', text:'- Seleccione una opcion - '}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.products_type.push({value: element.id_product_type, text: element.name });
      });
    }

  });
}

function SearchClients(search, loading) {
  
  let me = this;
   let url = this.url_base + "client/search-actives";
   if (search !== "") {

    let data = {
      search : search
    };

     loading(true);
     axios({
       method: "POST",
       url: url,
       data: data,
       headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
     }).then(function (response) {
           me.clients = response.data.result;
           loading(false);
     })
   }
}

function ShowModalClient() {
  EventBus.$emit('ModalClientsShow');
}

function AddDisbursement() {
  let me = this;
  let url = me.url_base + "disbursement/add";
  me.isLoading = true;
  let data = new FormData();
  data.append("id_user", this.user.id_user);
  data.append("id_branch_office", this.user.id_branch_office);
  data.append("id_credit", this.disbursement.id_credit);
  data.append("id_client", this.disbursement.id_client);
  data.append("id_module", 1); //prestamo
  data.append("code", this.disbursement.code);
  data.append("dues", this.disbursement.dues);
  data.append("payment_method", this.disbursement.payment_method);
  data.append("date", this.disbursement.date);
  data.append("description", this.disbursement.description);
  data.append("file", this.disbursement.file);
  data.append("amount", this.disbursement.amount);
  data.append("state", this.disbursement.state);
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 201) {
        me.modal_disbursement = false;
        EventBus.$emit('RefreshDisbursements');
       // me.$router.push({ name: "DisbursementList" });
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_client = this.disbursement.id_client.length == 0 ? true : false;
  this.errors.payment_method = this.disbursement.payment_method.length == 0 ? true : false;
  this.errors.date = this.disbursement.date.length == 0 ? true : false;
  this.errors.amount = this.disbursement.amount.length == 0 ? true : false;

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.name) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.date) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.amount) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de registrar el desembolso?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.AddDisbursement();
    }
  });
}

function onFileChange(e) {
  this.disbursement.file = e.target.files[0];
}

function ListModule() {
  let me = this;
  let url = me.url_base + "module/actives";
  me.modules = [{ value: '', text:'- Seleccione una opcion -'}];
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      response.data.result.forEach(element => {
        me.modules.push({value: element.id_module, text: element.name });
      });
    }
  });
}

</script>
